import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

export const FeaturePageTemplate = ({ image, title, subtitle, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Hero
        image={!image.childImageSharp ? image : image.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
      />
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <PageContent className="content is-size-5" content={content} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

FeaturePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const FeaturePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FeaturePageTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        content={data.markdownRemark.html}
      />
    </Layout>
  );
};

FeaturePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FeaturePage;

export const featurePageQuery = graphql`
  query FeaturePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
